.bottom-bar {
  border: solid 1px black;
}

.popup-button * {
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -ms-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
  vertical-align: middle;
}
.popup-button:hover * {
  -webkit-transform:scale(1.2); /* Safari and Chrome */
  -moz-transform:scale(1.2); /* Firefox */
  -ms-transform:scale(1.2); /* IE 9 */
  -o-transform:scale(1.2); /* Opera */
  transform:scale(1.2);
}